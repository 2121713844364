body,
html,
#root {
  height: 100%;
  width: 100%;
  /* font-family: Graphik, 'Helvetica Neue'; */
  font-weight: 400;
  background-color: #fff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page {
  min-height: 100%;
  display: flex;
  flex-direction: row;
}
.content {
  position: relative;
  max-height: 100%;
  overflow: auto;
}

nav {
  min-height: 100%;
  min-width: 300px;
  background-color: #fff;
  margin-right: 50px;
  text-align: center;
  border-right: 3px solid #445a7d;
}

nav ul {
  margin-top: 50px;
  margin-left: 30px;
  list-style-type: none;
  text-align: left;
}

.tick line {
  stroke: #fff;
  stroke-width: 3px;
}

.tick text {
  fill: #635f5d;
}
.bar-chart .tick rect {
  transition: filter 0.3s ease;
  filter: brightness(50%);
  opacity: 0.8;
}
.bar-chart .tick rect:hover {
  filter: brightness(80%);
}
.mark {
  fill: #fafafa;
}

.mark.selected {
  fill: #0096ff;
}

text.mark-label {
  fill: #36476c;
  font-weight: 600;
  pointer-events: none;
}

.chart-title {
  font-size: 32px;
  font-weight: 600;
  fill: #36476c;
}

.kpi .chart-title {
  font-size: 18px;
}
.bar-chart g.tick.bar {
  transition: transform 0.5s ease;
}

.kpi-figure {
  font-size: 50px;
  fill: #1cc691;
}

.kpi polygon {
  fill: #1cc691;
}

svg {
  cursor: help;
  background-color: #cfe9ff;
  border: 2px solid #f2f2f2;
  margin: 15px;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}
svg:hover {
  border-color: #0096ff;
}

.widget-label {
  fill: #36476c;
  font-size: 24px;
}

.widget .tick {
  cursor: pointer;
}

.dashboard {
  width: 990px;
}
h1,
h2 {
  font-size: 36px;
  line-height: 2em;
  color: #36476c;
}

h1 {
  text-align: center;
}

h3 {
  font-size: 24px;
  line-height: 1em;
  color: #36476c;
}

.accent {
  color: #36476c;
  font-weight: 600;
}

#tooltip p {
  margin: 8px 0;
}

.zoom {
  position: absolute;
  z-index: 1000;
  top: 20px;
  left: 15px;
}

.tooltip {
  pointer-events: none;
  background-color: white;
  padding: 20px;
  border: 2px solid #445a7d;
  box-shadow: 1px 1px 5px #0008;
  transition: opacity 0.5s ease;
}

.help {
  margin-right: 50px;
  margin-top: 50px;
  max-width: 800px;
}

p {
  margin: 1em 0;
}

.help .banner {
  margin: 40px;
  margin-top: 100px;
  padding: 120px 3em 2em;
  background-color: #cfe9ff;
  position: relative;
}

.help .banner .chris {
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  border: 40px solid #cfe9ff;
}

th {
  color: #36476c;
}

td,
th {
  text-align: center;
  padding: 5px 15px;
}

td.left {
  text-align: left;
}

tr.even {
  background-color: #cfe9ff;
}

button {
  margin: 10px;
  padding: 3px 10px;
  border: 2px solid #0096ff;
  border-radius: 3px;
  background-color: #fff;
  font-weight: 600;
  color: #36476c;
}

button.selected {
  background: #0096ff;
  color: #fff;
}

.asdfg {
  height: 300px;
  width: 300px;
  background-color: #cfe9ff;
  margin: 15px;
  display: flex;
  flex-direction: column;
}

.asdfg h4 {
  font-size: 18px;
  color: #36476c;
  line-height: 3em;
  text-align: center;
}
.top-line {
  display: flex;
}

button {
  cursor: pointer;
}

nav a:hover {
  color: #0096ff;
}
nav a {
  text-decoration: none;
  color: #36476c;
  font-weight: 600;
}
nav .category {
  margin-top: 10px;
}
nav .child {
  margin-left: 15px;
}

.hover-bright {
  transition: filter 0.3s ease;
}
.hover-bright:hover {
  filter: brightness(1.2);
}
